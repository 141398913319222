<template>
  <div v-if="showDisplayModeIcon">
    <v-tooltip
      top
      v-for="mode in modes"
      :key="mode.type"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          v-if="hasMode(mode.type)"
          @click="changeMode(mode.type)"
          :color="getColor(mode.type)"
        >
          <v-icon>{{mode.icon}}</v-icon>
        </v-btn>
      </template>

      <span>{{'list.display_mode'|t}}: {{`list.display_mode.${mode.type}`|t}}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  methods: {
    hasMode(type) {
      if(!Array.isArray(this.displayModeTypes)) return false
      return this.displayModeTypes.includes(type)
    },
    async changeMode(type) {
      if(this.value === this.displayMode) return
      this.displayMode = type
      await this.$nextTick()
      this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
    },
    getColor(type) {
      if(type == this.displayMode) return 'primary'
      return 'default'
    },
  },
  computed: {
    modes() {
      return [
        { type: 'card', icon: 'fa fa-list' },
        { type: 'table', icon: 'fa fa-th' },
      ]
    },
    displayModeTypes() {
      return this.$store.getters[`list/${this.listKey}/displayModeTypes`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    displayMode: {
      get() {
        return this.$store.getters[`list/${this.listKey}/displayMode`]
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'displayMode',
          value,
        })
      },
    },
    showDisplayModeIcon() {
      if(!Array.isArray(this.displayModeTypes)) return false
      if(this.displayModeTypes.length == 0) return false
      return true
    },
    routeQueryDisplayMode() {
      const query = this.$route.query
      return query.display === 'card' ? 'card' : 'table'
    },
  },
  watch: {
    displayMode: {
      handler(after, before) {
        if(this.displayMode == this.routeQueryDisplayMode) return
        this.listActions.changeIndexCondition()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>